<template>
  <v-card tile>
    <v-toolbar color="primary" dark>
      <v-btn dark icon @click.native="$emit('close-dialog')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer/>
      <v-toolbar-title :style="{ 'margin-left': '-24px' }">
        {{ $vuetify.lang.t('$vuetify.pages.inspections.inspectors') }}
      </v-toolbar-title>
      <v-spacer/>
    </v-toolbar>
    <kurcc-staff-list :selected-ids.sync="selectedIds" show-select-button/>
    <v-btn :disabled="!anyItemsSelected" :loading="loading" :width="'210px'" bottom class="center-x" color="primary"
           elevation="2" fixed @click="assignInspectionToInspector">
      {{ $vuetify.lang.t('$vuetify.pages.inspections.addToInspectorBtn') }}
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: 'KurccInspectionsSelectInspectorDialogContent',
  components: {
    KurccStaffList: () => import('@/modules/app/components/KurccStaffList')
  },
  props: {
    inspectionIds: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedIds: [],
      loading: false
    }
  },
  computed: {
    anyItemsSelected () {
      return this.selectedIds.length > 0
    }
  },
  methods: {
    assignInspectionToInspector () {
      if (this.anyItemsSelected) {
        this.loading = true
        this.$store.dispatch('assignInspectorToInspections', {
          inspector_id: this.selectedIds[0],
          inspection_ids: this.inspectionIds
        }).then(() => {
          this.selectedIds = []
          this.$emit('close-dialog')
          this.$emit('change')
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.$emit('close-dialog')
      }
    }
  }
}
</script>
